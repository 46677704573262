@import 'ui/utils';

$borderSize: 2;

.wrapper {
  border: rem($borderSize) solid cl(deep-dark-blue);
  background: cl(pure-white);
  display: flex;
  align-items: center;
  border-radius: rem(30);
  padding: 0 rem(24);
  transition: box-shadow 0.4s;

  &.isFocused {
    @include focused();
  }

  &--secondary {
    border-color: cl(border-dark);
  }

  &--error {
    border-color: cl(attention);

    &.isFocused {
      @include focused(cl(attention, 0.5));
    }
  }

  &--sm {
    $borderSmSize: 1;

    padding: 0 rem(12);
    border-width: rem($borderSmSize);

    .input {
      height: rem(32 - (2 * $borderSmSize));
      font-size: rem(14);
      font-weight: 400;
    }
  }
}

.input {
  border: none;
  outline: none;
  flex: 1 1 auto;
  min-width: 0;
  padding: 0;
  height: rem(56 - (2 * $borderSize));
  font-size: rem(18);
  font-weight: 700;
  color: cl(deep-dark-blue);
  background: cl(pure-white);

  &::placeholder {
    font-weight: 400;
    color: cl(border-dark);
    text-overflow: ellipsis;
  }
}

.label {
  padding: 0 0 rem(6) rem(26);
  display: block;
  font-size: rem(14);
}

.errors {
  margin-top: rem(10);
  margin-left: rem(24);
  color: cl(attention);
}

.after {
  margin-top: rem(10);
  margin-left: rem(24);
}
