@use "sass:math";

@import '~normalize.css';

@import './utils';

:root {
  @include globalColors();
}

*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  font-family: $baseFontFamily;
  touch-action: manipulation;
}

@include tighter-than-desktop {
  html {
    font-size: $baseFontSize * math.div(12, 14);
  }
}

body {
  font-weight: 400;
  color: cl(deep-dark-blue);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
