@use "sass:math";

@import 'palette';
@import 'variables';
@import 'media';
@import 'browsers';

@function rem($size, $context: $baseFontSize) {
  @if (unitless($size)) {
    $size: $size * 1px;
  }

  // Fix less then 1px problem
  @if ($size == 1px or $size == -1px) {
    @return $size;
  }

  @if (unitless($context)) {
    $context: $context * 1px;
  }

  @return math.div($size, $context) * 1rem;
}

@mixin removeTapHightlight() {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
  user-select: none;
}

@mixin focused($color: cl(focused-shadow, 0.5)) {
  box-shadow: 0 0 rem(8) $color;
}

@mixin breakWord() {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  hyphens: auto;
}

@mixin unstyledList {
  list-style: none;
  padding: 0;
  margin: 0;
}

@mixin stripeGrid {
  &Left,
  &Right,
  &Center {
    display: flex;
    align-items: center;
  }

  &Left,
  &Right {
    flex: 1 1 0%;
    flex: 1 1 0%;
  }

  &Left {
    min-width: 0;
    justify-content: flex-start;
  }

  &Right {
    justify-content: flex-end;
  }

  &Center {
    flex: 0 0 auto;
  }
}

@mixin cross($color, $activeColor: false) {
  cursor: pointer;
  height: rem(13);
  width: rem(13);
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  outline: none;
  position: relative;

  &:before,
  &:after {
    background: $color;
    border-radius: rem(3);
    content: '';
    display: block;
    height: rem(2);
    width: rem(15);
    position: absolute;
    top: 50%;
    margin-left: rem(-1);
    transform-origin: 50% 50%;
    transition: transform 0.35s, background 0.35s;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  @if $activeColor {
    &:hover {
      &:before,
      &:after {
        background: $activeColor;
      }
    }
  }
}

@mixin fullHeight($property: 'height') {
  #{$property}: 100vh;

  @include browserSafari {
    /* mobile viewport bug fix */
    #{$property}: -webkit-fill-available;
  }
}
