@import 'ui/utils';

.avatar {
  border-radius: 50%;
  width: rem(32);
  height: rem(32);
  display: flex;
  align-items: center;
  justify-content: center;
  border: rem(1) solid cl(dark-theme);
  background: cl(theme);
  color: cl(pure-white);
}

.tooltip {
  z-index: 10;
}
