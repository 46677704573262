@import './utils';

.center {
  text-align: center;
}

.pb {
  padding-bottom: rem(20);
}

.link {
  color: cl(theme);
  transition: color 0.3s;

  &:visited {
    color: cl(attention);
  }
}
