@import 'ui/utils';

$carpetSize: 10;

:export {
  carpetSize: $carpetSize;
}

.tooltip {
  $root: &;

  &Arrow {
    &[data-hide]:before {
      visibility: hidden;
    }

    &:before {
      content: '';
      display: block;
      width: rem(10);
      height: rem(10);
      background-color: cl(middle-dark);
      transform: rotate(45deg);
    }
  }

  &[data-popper-placement^='top'] {
    > #{$root}Arrow {
      bottom: rem(-($carpetSize * 0.5));
    }
  }

  &[data-popper-placement^='bottom'] {
    > #{$root}Arrow {
      top: rem(-($carpetSize * 0.5));
    }
  }

  &[data-popper-placement^='left'] {
    > #{$root}Arrow {
      right: rem(-($carpetSize * 0.5));
    }
  }

  &[data-popper-placement^='right'] {
    > #{$root}Arrow {
      left: rem(-($carpetSize * 0.5));
    }
  }

  &Content {
    background: cl(middle-dark);
    color: cl(pure-white);
    padding: rem(5) rem(10);
    border-radius: rem(8);
    max-width: rem(250);
    width: max-content;
  }

  &Wrapper {
    position: relative;
  }
}

.enter {
  composes: enter from '../transitions/FromBottomSide.module.scss';
  pointer-events: none;

  transform: translate3d(-50%, 100%, 0);
}

.enterActive {
  composes: enterActive from '../transitions/FromBottomSide.module.scss';
  pointer-events: none;

  transform: translate3d(-50%, 0, 0);
}

.exit {
  composes: exit from '../transitions/FromBottomSide.module.scss';

  transform: translate3d(-50%, 0, 0);
}

.exitActive {
  composes: exitActive from '../transitions/FromBottomSide.module.scss';

  transform: translate3d(-50%, 100%, 0);
}
