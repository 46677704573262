@import 'ui/utils';

.layout {
  display: flex;
  overflow: hidden;

  @include fullHeight();
}

.present {
  flex: 1 1 auto;
  background-color: cl(middle-dark);
  width: calc(100% - #{rem(355)});
  display: flex;
  transition: width 0.4s;
  transition: margin-left 0.4s;
  &Hidden {
    width: 0;
    margin-left: -100%;
  }
}

.conference {
  border-left: rem(1) solid cl(border-dark);
  width: rem(355);
  position: relative;
  transition: width 0.4s;

  &IsClose {
    width: 0;
  }

  &FullScreen {
    width: 100%;
  }

  &HideButton {
    margin-left: rem(-1);
  }
  &HideButtonReverse {
    left: 0;
  }
}

@include mobile {
  .layout {
    flex-direction: column;
  }

  .present {
    flex: 1 0 auto;
    width: 100%;
    &Hidden {
      margin-left: auto;
    }
  }

  .conference {
    width: 100%;
    height: 70vh;
    &HideButtonReverse {
      display: none;
    }
  }
}
