@import 'ui/utils';

.wrapper {
  height: rem(60);
  background-color: cl(light-theme-bg);
  display: flex;
  align-items: center;
  padding: 0 rem(20);
  border-bottom: rem(1) solid cl(border-dark);

  &Left,
  &Right {
    display: flex;
    flex: 1 1 auto;
  }

  &Right {
    flex-direction: row-reverse;
  }
}

.abilities {
  width: auto;

  > div {
    flex: 1 1 auto;
    margin-right: rem(15);
    min-width: rem(120);

    &:last-child {
      margin-right: 0;
    }
  }

  &:after {
    content: '';
    height: rem(1);
    flex: 1;
  }
}
