@import 'ui/utils';

.wrapper {
  display: flex;
}

.avatar {
  margin-right: rem(10);
}

.extendedList {
  background: cl(light-theme-bg);
  position: absolute;
  right: 0;
  top: rem(60);
  bottom: 0;
  width: rem(320);
  padding: rem(14) rem(12);
  z-index: 1;
  overflow: auto;
}

.userRow {
  display: flex;
  align-items: center;
  padding: rem(10) 0;
  border-bottom: rem(1) solid cl(border-light);

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: 0;
  }

  &Avatar {
    margin-right: rem(15);
  }

  &Actions {
    flex: 1;
    display: flex;
    flex-direction: row-reverse;
  }
}
