@import 'ui/utils';

.heading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: rem(20);
  display: flex;
  justify-content: flex-end;

  button + button {
    margin-left: rem(15);
  }
}

.userMenu {
  @include unstyledList();
  margin: rem(-3) rem(-10);

  li {
    border-bottom: rem(1) solid cl(border-dark);

    &:last-child {
      border-bottom: none;
    }
  }

  li a {
    padding: rem(5) rem(12);
    display: block;
    color: cl(pure-white);
    text-decoration: none;
    font-size: rem(15);
    transition: color 0.3s;

    &:hover {
      color: cl(pure-white, 0.7);
    }
  }

  &Caption {
    text-shadow: rem(1) rem(1) rem(1) cl(pure-white);
  }
}
